<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '50%' }" header="Visualizar" @hide="close">
        <div v-if="formResumido" class="grid">
            <div class="col-12 card table">
                <label>Resumo</label>
                <table class="w-full">
                    <tr v-for="(value, key) in formResumido" :key="key">
                        <td v-if="value">
                            <strong>{{ value.name }}</strong>
                        </td>
                        <td v-if="value">
                            <div>{{ value.value }}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <Divider/>
        <div v-if="form.objetoAtualizacao" class="grid">
            <div class="col-12 card table">
                <label>Detalhes do Objeto</label>
                <table class="w-full">
                    <tr v-for="(value, key) in form.objetoAtualizacao" :key="key">
                        <td v-if="value">
                            <strong>{{ key }}</strong>
                        </td>
                        <td v-if="value">
                            <div>{{ value }}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Fechar" @click="close" />
        </template>
    </Dialog>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
            form: {},
            formResumido: {}
        };
    },
    methods: {
        open(record) {
            this.form.objetoAtualizacao = record.origem === 'DUON' ? record.dadoDuon : record.dadoSenior;
            const obj = record.origem === 'DUON' ? record.dadoDuon : record.dadoSenior;
            this.formResumido = this.criarFormResumido(obj);
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.form = {};
            this.formResumido = {};
        },
        criarFormResumido(dados) {
            return {
                nomeFuncionario: { value: dados.nomeFuncionario, name: 'Nome Funcionário' },
                categoriaEsocial: { value: dados.categoriaESocial, name: 'Categoria e-Social' },
                textoSituacao: { value: dados.situacao, name: 'Situação' },
                codigoRhUnidade: { value: dados.codigoRhUnidade, name: 'Código Rh Unidade' },
                codigoRhSetor: { value: dados.codigoRhSetor, name: 'Código Rh Setor' },
                codigoRhCargo: { value: dados.codigoRhCargo, name: 'Código Rh Cargo' }
            };
        }
    }
};
</script>

<style scoped>
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}
</style>

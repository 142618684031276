const StatusIntegracaoSeniorFuncionarioItemEnum = Object.freeze({
    CRIADO: 'Criado',
    NADA_A_ATUALIZAR: 'Nada a atualizar',
    INATIVADO_NA_DUON: 'Inativado na Duon',
    ERRO_AO_INATIVAR_DUON: 'Erro ao inativar na Duon',
    FUNCIONARIO_CADASTRADO_NA_DUON: 'Funcionário Cadastrado na Duon',
    FUNCIONARIO_ATUALIZADO_NA_DUON: 'Funcionário Atualizado na Duon',
    ERRO_AO_CADASTRAR_FUNCIONARIO_DUON: 'Erro ao Cadastrar Funcionário na Duon',
    ERRO_AO_ATUALIZAR_FUNCIONARIO_DUON: 'Erro ao Atualizar Funcionário na Duon',
    ERRO_AO_ATUALIZAR_HIERARQUIA: 'Erro ao Atualizar Hierarquia',
    HIERARQUIA_INSERIDA: 'Hierarquia Inserida',
    ERRO_AO_INSERIR_HIERARQUIA: 'Erro ao Inserir Hierarquia',
    ATIVIDADE_ESPECIFICA_ATUALIZADA: 'Atividade Especifica Atualizada',
    ERRO_AO_ATUALIZAR_ATIVIDADE_ESPECIFICA: 'Erro ao Atualizar Atividade Especifica',
    CANCELADO: 'Cancelado',
    FINALIZADO: 'Finalizado',
    ERRO: 'Erro',
})

export default StatusIntegracaoSeniorFuncionarioItemEnum;

<template>
    <AppPanel subtitle="Preencha os campos abaixo">
        <template #content>
            <AppBackButton path="/integracao-senior" class="mr-0 mt-3 mb-2 md:mr-3 md:mt-0" />
            <GridIntegracaoSeniorItens />
        </template>
    </AppPanel>
</template>

<script>
import GridIntegracaoSeniorItens from './components/GridIntegracaoSeniorItens';

export default {
    components: {
        GridIntegracaoSeniorItens
    }
};
</script>

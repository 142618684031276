<template>
    <div>
        <AppPaginatedGrid
            ref="grid"
            :service="service"
            :actionItems="actionItems"
            @onToggleMenu="onToggleMenu"
            :filtrosExtras="filtrosExtras"
            :hasFiltro="false"
        >
            <template #columns>
                <Column field="dadoSenior.nomeFuncionario" header="Funcionário">
                    <template #body="{ data }">
                        <p>{{ data.origem === 'DUON' ? data.dadoDuon.nomeFuncionario : data.dadoSenior.nomeFuncionario }}</p>
                    </template>
                </Column>
                <Column field="status" header="Status">
                    <template #body="{ data }">
                        <Tag :style="tagStatusStyle(data.status)" :value="enumStatusIntegracaoSeniorFuncionarioItem[data.status]" />
                    </template>
                </Column>
                <Column field="erro" header="Erro" />
            </template>

            <template #anotherFilters>
                <div class="flex flex-column p-fluid mt-2 md:mt-0 justify-items-between gap-2">
                    <InputText v-model="filtrosExtras.nomeFuncionario" placeholder="Pesquisar" class="md:w-10" @keypress.enter="$refs.grid.load()" />
                    <div class="field-checkbox">
                        <Checkbox id="filtroPossuiErro" :binary="true" v-model="filtrosExtras.possuiErro" />
                        <label for="filtroPossuiErro">Mostrar apenas com erro</label>
                    </div>
                </div>
            </template>
        </AppPaginatedGrid>
        <DialogIntegracaoSeniorItem ref="dialogIntegracaoSeniorItem" />
    </div>
</template>

<script>
import MicroserviceSenior from '../../../services/MicroserviceSenior';
import DialogIntegracaoSeniorItem from './DialogIntegracaoSeniorItem';
import StatusIntegracaoSeniorFuncionarioItemEnum from '../../../enums/StatusIntegracaoSeniorFuncionarioItemEnum';

export default {
    components: {
        DialogIntegracaoSeniorItem
    },
    data() {
        return {
            filtrosExtras: {
                possuiErro: false,
                nomeFuncionario: null
            },
            service: null,
            enumStatusIntegracaoSeniorFuncionarioItem: StatusIntegracaoSeniorFuncionarioItemEnum,
            actionItems: [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-eye',
                    command: () => this.visualizar(this.record)
                }
            ]
        };
    },
    created() {
        this.service = new MicroserviceSenior(`/integracao/buscar-registros-integracoes-items/${this.$route.params.id}`);
    },
    watch: {
        'filtrosExtras.possuiErro'() {
            this.$refs.grid.load();
        }
    },
    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        },
        visualizar(record) {
            this.$refs.dialogIntegracaoSeniorItem.open(record);
        },
        tagStatusStyle(status) {
            const errorStatuses = [
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_ATUALIZAR_FUNCIONARIO_DUON,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_CADASTRAR_FUNCIONARIO_DUON,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_ATUALIZAR_ATIVIDADE_ESPECIFICA,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_INSERIR_HIERARQUIA,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_INATIVAR_DUON,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO_AO_ATUALIZAR_HIERARQUIA,
                StatusIntegracaoSeniorFuncionarioItemEnum.ERRO,
            ];
            if (errorStatuses.includes(StatusIntegracaoSeniorFuncionarioItemEnum[status])) {
                return {
                    'background-color': 'var(--pink-200)',
                    color: 'var(--pink-800)'
                };
            }

            if (
                StatusIntegracaoSeniorFuncionarioItemEnum[status] === StatusIntegracaoSeniorFuncionarioItemEnum.FINALIZADO
            ) {
                return {
                    'background-color': 'var(--green-300)',
                    color: 'var(--green-900)'
                };
            }

            return {
                'background-color': 'var(--blue-200)',
                color: 'var(--blue-900)'
            };
        }
    }
};
</script>
